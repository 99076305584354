import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GUEST_FEATURE_KEY, GuestState, guestAdapter } from './guest.reducer';

// Lookup the 'Guest' feature state managed by NgRx
export const getGuestState =
  createFeatureSelector<GuestState>(GUEST_FEATURE_KEY);

const { selectAll, selectEntities } = guestAdapter.getSelectors();

export const getGuestLoaded = createSelector(
  getGuestState,
  (state: GuestState) => state.loaded
);

export const getGuestError = createSelector(
  getGuestState,
  (state: GuestState) => state.error
);

export const getAllGuest = createSelector(getGuestState, (state: GuestState) =>
  selectAll(state)
);

export const getGuestEntities = createSelector(
  getGuestState,
  (state: GuestState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getGuestState,
  (state: GuestState) => state.selectedId
);

export const getSelected = createSelector(
  getGuestEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
