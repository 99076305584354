import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TOASTS_FEATURE_KEY,
  ToastsState,
} from './toasts.reducer';

// Lookup the 'Toasts' feature state managed by NgRx
export const getToastsState = createFeatureSelector<ToastsState>(TOASTS_FEATURE_KEY);

export const getAllToasts = createSelector(getToastsState, (state: ToastsState) => state.toasts);
