import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@ngrx/router-store/data-persistence';
import { filter, map } from 'rxjs';
import { GameService } from '../game.service';

import * as GameActions from './game.actions';
import { selectTrial } from './game.selectors';

@Injectable()
export class GameEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GameActions.initGame),
      fetch({
        run: (action) => {
          // Your custom service 'load' logic goes here. For now just return a success action...;
        },
        onError: (action, error) => {
          console.error('Error', error);
        },
      })
    )
  );

  loadStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GameActions.loadStep),
      concatLatestFrom(() => this.store.select(selectTrial)),
      map(([action, trial]) => ({ ...action, trial })),
      fetch({
        run: ({ trial }) => {
          if (trial) {
            return this.service
              .step(trial?.id)
              .pipe(map((result) => GameActions.loadStepSuccess({ result })));
          }
          return GameActions.loadStepFailure({ error: 'No trial selected' });
        },
        onError: (action, error) => {
          return GameActions.loadStepFailure({ error });
        },
      })
    )
  );

  next$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GameActions.next),
      concatLatestFrom(() => this.store.select(selectTrial)),
      map(([action, trial]) => ({ ...action, trial })),
      pessimisticUpdate({
        run: ({ interaction, trial, document, attachments }) => {
          return this.service
            .next(trial?.id ?? 0, interaction, document, attachments)
            .pipe(map((feedback) => GameActions.nextSuccess({ feedback })));
        },
        onError: (action, error) => {
          return GameActions.loadStepFailure({ error });
        },
      })
    )
  );

  // If there is no feedback, load next step
  nextSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GameActions.nextSuccess),
      filter(({ feedback }) => !feedback),
      map(() => GameActions.loadStep())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: GameService,
    private readonly store: Store
  ) {}
}
