import { Subscription } from '@lv/legal-battles/api-interfaces';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as SubscriptionsActions from './subscriptions.actions';

export const SUBSCRIPTIONS_FEATURE_KEY = 'subscriptions';

export interface SubscriptionsState extends EntityState<Subscription> {
  selectedId?: string | number; // which Subscriptions record has been selected
  loaded: boolean; // has the Subscriptions list been loaded
  error?: string | null; // last known error (if any)
}

export interface SubscriptionsPartialState {
  readonly [SUBSCRIPTIONS_FEATURE_KEY]: SubscriptionsState;
}

export const subscriptionsAdapter: EntityAdapter<Subscription> =
  createEntityAdapter<Subscription>();

export const initialSubscriptionsState: SubscriptionsState =
  subscriptionsAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialSubscriptionsState,
  on(SubscriptionsActions.loadSubscriptions, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    SubscriptionsActions.loadSubscriptionsSuccess,
    (state, { subscriptions }) =>
      subscriptionsAdapter.setAll(subscriptions, { ...state, loaded: true })
  ),
  on(SubscriptionsActions.loadSubscriptionsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(SubscriptionsActions.selectSubscription, (state, { subscription }) => ({
    ...state,
    selectedId: subscription.id
  })),
);

export function subscriptionsReducer(
  state: SubscriptionsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
