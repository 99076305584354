import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LawOfficesService } from './+state/law-offices.service';
import { LawOfficesEffects } from './+state/law-offices.effects';
import { LawOfficesFacade } from './+state/law-offices.facade';
import * as fromLawOffices from './+state/law-offices.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromLawOffices.LAW_OFFICES_FEATURE_KEY,
      fromLawOffices.lawOfficesReducer
    ),
    EffectsModule.forFeature([LawOfficesEffects]),
  ],
  providers: [LawOfficesFacade, LawOfficesService],
})
export class LegalBattlesDataLawOfficesModule {}
