import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IdField, Trial } from '@lv/legal-battles/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrialsService {
  constructor(private http: HttpClient) {}

  find(id: IdField): Observable<Trial> {
    return this.http.get<Trial>(`/api/trials/${id}`);
  }

  findAll(): Observable<Trial[]> {
    return this.http.get<Trial[]>('/api/trials');
  }

  save(trial: Trial): Observable<Trial> {
    if (trial.id) {
      return this.http.put<Trial>(`/api/trials/${trial.id}`, trial);
    }
    return this.http.post<Trial>('/api/trials', trial);
  }

  delete(trial: Trial) {
    return this.http.delete(`/api/trials/${trial.id}`);
  }
}
