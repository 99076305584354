import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameFacade } from '@lv/legal-battles/data-game';
import { TutorFacade } from '@lv/legal-battles/data-tutor';
import { SubscriptionsFacade, playerSubscriptionSuccess, selectSubscription, tutorSubscriptionSuccess } from '@lv/legal-battles/data-subscriptions';
import { AuthFacade, loadProfileSuccess } from '@lv/shared/data-auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, iif, map, of, switchMap, tap, withLatestFrom, zip } from 'rxjs';
import { loadLawOffice, loadTrial, performRedirect } from './guest.actions';
import { TrialsService } from '@lv/legal-battles/data-trials';
import { LawOfficesService } from '@lv/legal-battles/data-law-offices';


@Injectable()
export class GuestEffects {

  selectSubcription$ = createEffect(() => this.actions$.pipe(
    ofType(selectSubscription),
    switchMap(({ subscription }) =>
      iif(
        () => subscription.role === 'PLAYER',
        of(playerSubscriptionSuccess({ subscription })),
        of(tutorSubscriptionSuccess({ subscription }))
      )
    )
  ));

  playerSubscriptionSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(playerSubscriptionSuccess),
      switchMap(({ subscription }) => zip([
        this.trialsService.find(subscription.trialId),
        this.lawOfficeService.find(subscription.lawOfficeId ?? 0),
      ])),
      tap(([trial, lawOffice]) => lawOffice && this.gameFacade.setGame(lawOffice, trial)),
      tap(() => this.gameFacade.loadStep()),
      map(() => performRedirect()),
  ));

  tutorSubscriptionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(tutorSubscriptionSuccess),
    switchMap(({ subscription }) => this.trialsService.find(subscription.trialId)),
    tap((trial) => this.tutorFacade.setTutor(trial)),
    tap((trial) => this.tutorFacade.setTutor(trial)),
    map(() => performRedirect()),
  ));

  performRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(performRedirect),
    withLatestFrom(
      this.authFacade.user$,
      this.subscriptionsFacade.selectedSubscriptions$
    ),
    filter(() => window.location.pathname === '/'),
    tap(([, user, subscription]) => {
      if (!user) {
        return;
      }
      if (subscription?.role === 'PLAYER') {
        this.router.navigate(['gioca']);
      } else {
        this.router.navigate(['tutor']);
      }
    }),
  ), { dispatch: false });

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly actions$: Actions,
    private readonly gameFacade: GameFacade,
    private readonly route: ActivatedRoute,
    private readonly tutorFacade: TutorFacade,
    private readonly trialsService: TrialsService,
    private readonly lawOfficeService: LawOfficesService,
    private readonly router: Router,
    private readonly subscriptionsFacade: SubscriptionsFacade,
  ) {}
}
