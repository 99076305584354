import { Component, OnInit } from '@angular/core';
import { Feedback, ToastsFacade, FeedbackType } from '@lv/shared/data-toasts';

@Component({
  selector: 'lv-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
})
export class ToastsComponent {

  toasts$ = this.toastsFacade.allToasts$;

  constructor(
    private toastsFacade: ToastsFacade
  ) {}

  getCssClass(toast: Feedback) {
    switch (toast.type) {
      case 'failure':
        return 'bg-danger text-light';

      case 'info':
        return 'bg-info text-light';

      case 'warning':
        return 'bg-warning text-light';

      case 'success':
        return 'bg-success text-light';
    
      default:
        return '';
    }
  }

  onHide(toast: Feedback) {
    this.toastsFacade.hide(toast);
  }

}
