import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SUBSCRIPTIONS_FEATURE_KEY,
  SubscriptionsState,
  subscriptionsAdapter,
} from './subscriptions.reducer';

// Lookup the 'Subscriptions' feature state managed by NgRx
export const getSubscriptionsState = createFeatureSelector<SubscriptionsState>(
  SUBSCRIPTIONS_FEATURE_KEY
);

const { selectAll, selectEntities } = subscriptionsAdapter.getSelectors();

export const getSubscriptionsLoaded = createSelector(
  getSubscriptionsState,
  (state: SubscriptionsState) => state.loaded
);

export const getSubscriptionsError = createSelector(
  getSubscriptionsState,
  (state: SubscriptionsState) => state.error
);

export const getAllSubscriptions = createSelector(
  getSubscriptionsState,
  (state: SubscriptionsState) => selectAll(state)
);

export const getSubscriptionsEntities = createSelector(
  getSubscriptionsState,
  (state: SubscriptionsState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getSubscriptionsState,
  (state: SubscriptionsState) => state.selectedId
);

export const getSelected = createSelector(
  getSubscriptionsEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getTrial = createSelector(
  getSelected,
  subscription => subscription?.trial,
)