import { createAction, props } from '@ngrx/store';
import { GuestEntity } from './guest.models';
import { IdField, LawOffice, Trial } from '@lv/legal-battles/api-interfaces';

export const initGuest = createAction('[Guest Page] Init');

export const performRedirect = createAction('[Guest Page] Perform Redirect');

export const loadGuestSuccess = createAction(
  '[Guest/API] Load Guest Success',
  props<{ guest: GuestEntity[] }>()
);

export const loadGuestFailure = createAction(
  '[Guest/API] Load Guest Failure',
  props<{ error: any }>()
);

export const loadTrial = createAction(
  '[Guest/API] Load Trial',
  props<{ trialId: IdField }>()
)

export const loadTrialSuccess = createAction(
  '[Guest/API] Load Trial Success',
  props<{ trial: Trial }>()
);

export const loadTrialFailure = createAction(
  '[Guest/API] Load Trial Failure',
  props<{ error: any }>()
);

export const loadLawOffice = createAction(
  '[Guest/API] Load LawOffice',
  props<{ lawofficeId: IdField }>()
)

export const loadLawOfficeSuccess = createAction(
  '[Guest/API] Load LawOffice Success',
  props<{ lawoffice: LawOffice }>()
);

export const loadLawOfficeFailure = createAction(
  '[Guest/API] Load LawOffice Failure',
  props<{ error: any }>()
);