import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuth from './+state/auth.reducer';
import { AuthEffects } from './+state/auth.effects';
import { AuthFacade } from './+state/auth.facade';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt-interceptor.interceptor';
import { OnlyAdminDirective } from './directives/only-admin.directive';
import { OnlyUserDirective } from './directives/only-user.directive';
import { LegalBattlesDataGuestModule } from '@lv/legal-battles/data-guest';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.authReducer),
    EffectsModule.forFeature([AuthEffects]),
    LegalBattlesDataGuestModule,
  ],
  providers: [
    AuthFacade,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  declarations: [
    OnlyAdminDirective,
    OnlyUserDirective
  ],
  exports: [
    OnlyAdminDirective,
    OnlyUserDirective
  ],
})
export class SharedDataAuthModule {}
