import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromGuest from './+state/guest.reducer';
import { GuestEffects } from './+state/guest.effects';
import { GuestFacade } from './+state/guest.facade';
import { LegalBattlesDataGameModule } from '@lv/legal-battles/data-game';
import { LegalBattlesDataTutorModule } from '@lv/legal-battles/data-tutor';
import { LegalBattlesDataSubscriptionsModule } from '@lv/legal-battles/data-subscriptions';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromGuest.GUEST_FEATURE_KEY, fromGuest.guestReducer),
    EffectsModule.forFeature([GuestEffects]),
    LegalBattlesDataGameModule,
    LegalBattlesDataTutorModule,
    LegalBattlesDataSubscriptionsModule,
  ],
  providers: [GuestFacade],
})
export class LegalBattlesDataGuestModule {}
