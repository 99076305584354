import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';
import { HttpErrorResponse } from '@angular/common/http';

// Lookup the 'Auth' feature state managed by NgRx
export const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const getAuthUser = createSelector(getAuthState, (state: AuthState) => state.user);
export const getAccessToken = createSelector(getAuthState, (state: AuthState) => state.access_token);
export const getAuthError = createSelector(getAuthState, (state: AuthState) => {
    if (state.error instanceof HttpErrorResponse) {
        if (state.error.status === 401) {
            return 'Email o password errati';
        }
        return '' + state.error.status ;
    }
    return state.error;
});