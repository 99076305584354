/**
 * Interface for the 'Toasts' data
 */
export type FeedbackType = 'success' | 'failure' | 'warning' | 'info';

export interface Feedback {
  heading?: string;
  message: string;
  type: FeedbackType;
}
