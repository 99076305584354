import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeedbackType } from '@lv/shared/data-toasts';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lv-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {

  constructor(
    public activeModal: NgbActiveModal,
  ) {}
  
  @Input()
  message?: string;

  @Input()
  heading?: string;

  @Input()
  color: FeedbackType = 'info';

  get cssClass(): string {
    switch (this.color) {
      case 'failure':
        return 'danger';
    
      default:
        return 'primary';
    }
  }
  
}
