import { Injectable, inject } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as TutorActions from './tutor.actions';
import * as TutorFeature from './tutor.reducer';
import * as TutorSelectors from './tutor.selectors';
import { Trial } from '@lv/legal-battles/api-interfaces';

@Injectable()
export class TutorFacade {
  private readonly store = inject(Store);

  trial$ = this.store.select(TutorSelectors.selectTutorTrial);

  setTutor(trial?: Trial) {
    this.store.dispatch(TutorActions.setTutor({ trial }))
  }
}
