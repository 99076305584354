import { Subscription } from '@lv/legal-battles/api-interfaces';
import { createAction, props } from '@ngrx/store';

export const loadSubscriptions = createAction('[Subscriptions/API] Load Subscriptions');

export const loadSubscriptionsSuccess = createAction(
  '[Subscriptions/API] Load Subscriptions Success',
  props<{ subscriptions: Subscription[] }>()
);

export const loadSubscriptionsFailure = createAction(
  '[Subscriptions/API] Load Subscriptions Failure',
  props<{ error: any }>()
);

export const selectSubscription = createAction(
  '[Subscriptions] Select Subscription',
  props<{ subscription: Subscription }>()
);

export const playerSubscriptionSuccess = createAction(
  '[Subscriptions] Player Subscription Success',
  props<{ subscription: Subscription }>()
);

export const tutorSubscriptionSuccess = createAction(
  '[Subscriptions] Tutor Subscription Success',
  props<{ subscription: Subscription }>()
);
