import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';

import * as ToastsActions from './toasts.actions';
import * as ToastsFeature from './toasts.reducer';

@Injectable()
export class ToastsEffects {
  constructor(private readonly actions$: Actions) {}
}
