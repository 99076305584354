import { User } from '@lv/legal-battles/api-interfaces';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Auth] Init');

export const login = createAction('[Auth] Login', props<{ username: string, password: string }>());

export const loginSuccess = createAction('[Auth] Login Success', props<{ access_token: string }>());

export const loginFailure = createAction('[Auth] Login Failure', props<{ error: any }>());

export const logout = createAction('[Auth] Logout');

export const loadProfile = createAction('[Auth] Load Profile');

export const loadProfileSuccess = createAction('[Auth] Load Profile Success', props<{ user: User }>());

export const loadProfileFailure = createAction('[Auth] Load Profile Failure', props<{ error: any }>());