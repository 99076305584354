import { Injectable } from '@angular/core';
import { Trial } from '@lv/legal-battles/api-interfaces';
import { CrudFacade } from '@lv/shared/util-crud-controller';
import { select, Store, Action } from '@ngrx/store';

import * as TrialsActions from './trials.actions';
import * as TrialsFeature from './trials.reducer';
import * as TrialsSelectors from './trials.selectors';

@Injectable()
export class TrialsFacade implements CrudFacade<Trial> {

  loaded$ = this.store.pipe(select(TrialsSelectors.getTrialsLoaded));
  allEntities$ = this.store.pipe(select(TrialsSelectors.getAllTrials));
  selectedEntity$ = this.store.pipe(select(TrialsSelectors.getSelected));

  constructor(private readonly store: Store) {}

  load() {
    this.store.dispatch(TrialsActions.initTrials());
  }

  save(trial: Trial) {
    this.store.dispatch(TrialsActions.saveTrial({ trial }));
  }

  delete(trial: Trial) {
    this.store.dispatch(TrialsActions.deleteTrial({ trial }));
  }
}
