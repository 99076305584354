import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTutor from './+state/tutor.reducer';
import { TutorEffects } from './+state/tutor.effects';
import { TutorFacade } from './+state/tutor.facade';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TrialInterceptor } from './trial.interceptor';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTutor.TUTOR_FEATURE_KEY, fromTutor.tutorReducer),
    EffectsModule.forFeature([TutorEffects]),
  ],
  providers: [
    TutorFacade,
    { provide: HTTP_INTERCEPTORS, useClass: TrialInterceptor, multi: true },
  ],
})
export class LegalBattlesDataTutorModule {}
