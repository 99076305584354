import { Feedback, Interaction, LawOffice, StepResult, Trial } from '@lv/legal-battles/api-interfaces';
import { createAction, props } from '@ngrx/store';

export const initGame = createAction('[Game Page] Init');

export const getStatus = createAction('[Game] Get Status');

export const next = createAction(
    '[Game] Next',
    props<{
        interaction: Interaction,
        document?: File,
        attachments?: File[],
        trial?: Trial,
    }>()
);

export const nextSuccess = createAction(
    '[Game] Next Success',
    props<{ feedback: Feedback }>()
);

export const setGame = createAction(
    '[Game] Set Game',
    props<{ lawOffice: LawOffice, trial?: Trial }>()
);

export const loadStep = createAction(
    '[Game] Load Step',
);

export const loadStepSuccess = createAction(
    '[Game] Load Step Success',
    props<{ result: StepResult }>()
);

export const loadStepFailure = createAction(
    '[Game] Load Step Failure',
    props<{ error: any }>()
);