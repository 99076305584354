import { Feedback, IdField, LawOffice, Round, Step, Trial } from '@lv/legal-battles/api-interfaces';
import { Action, createReducer, on } from '@ngrx/store';

import * as GameActions from './game.actions';

export const GAME_FEATURE_KEY = 'game';

export interface GameState {
  lawOffice?: LawOffice;
  trial?: Trial;
  round?: Round;
  step?: Step;
  subject?: IdField;
  score: number;
  loading: boolean;
  feedback?: Feedback;
  stepCompleted?: boolean;
  paused?: boolean;
  completed?: boolean;
}

export interface GamePartialState {
  readonly [GAME_FEATURE_KEY]: GameState;
}

export const initialGameState: GameState = {
  score: 0,
  loading: true,
};

const reducer = createReducer(
  initialGameState,
  on(GameActions.initGame, (state) => ({ ...state, })),
  on(GameActions.setGame, (state, { lawOffice, trial }) => ({ ...state, lawOffice, trial })),
  on(GameActions.getStatus, GameActions.loadStep, (state) => ({ ...state, loading: true })),
  on(GameActions.nextSuccess, (state, { feedback }) => ({ ...state, feedback })),
  on(GameActions.loadStep, (state) => ({ ...state, step: undefined, subject: undefined })),
  on(GameActions.loadStepSuccess, GameActions.loadStepFailure, (state) => ({ ...state, loading: false })),
  on(GameActions.loadStepSuccess, (state, { result: { round, step, subject, score, paused, stepCompleted, completed } }) => ({ ...state, round, step: convertStep(step), subject, score, feedback: undefined, paused, stepCompleted, completed })),
);

function convertStep(step: Step): Step {
  return {
    ... step,
    content: JSON.parse(step.content as string)
  }
}

export function gameReducer(state: GameState | undefined, action: Action) {
  return reducer(state, action);
}
