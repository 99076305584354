import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlayerGuard } from './player.guard';
import { TutorGuard } from './tutor.guard';

const routes: Routes = [
  {
    path: 'gioca',
    canActivate: [PlayerGuard],
    loadChildren: () => import('@lv/legal-battles/container-player').then(m => m.LegalBattlesContainerPlayerModule),
  },
  {
    path: 'tutor',
    canActivate: [TutorGuard],
    loadChildren: () => import('@lv/legal-battles/container-tutor').then(m => m.LegalBattlesContainerTutorModule),
  },
  {
    path: 'messaggi',
    canActivate: [PlayerGuard],
    loadChildren: () => import('@lv/legal-battles/feature-messages').then(m => m.LegalBattlesFeatureMessagesModule),
  },
  { path: 'roles', loadChildren: () => import('@lv/legal-battles/feature-roles').then(m => m.LegalBattlesFeatureRolesModule) },
  { path: 'trials', loadChildren: () => import('@lv/legal-battles/admin/feature-trials').then(m => m.LegalBattlesFeatureTrialsModule) },
  { path: '', pathMatch: 'full', loadChildren: () => import('@lv/legal-battles/feature-guest').then(m => m.LegalBattlesFeatureGuestModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
