import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import * as LawOfficesActions from './law-offices.actions';

import { selectTrial } from '@lv/legal-battles/data-game';
import { Action, Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { LawOfficesService } from './law-offices.service';

@Injectable()
export class LawOfficesEffects {
  private actions$ = inject(Actions);
  private service = inject(LawOfficesService);
  private store = inject(Store);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LawOfficesActions.initLawOffices),
      concatLatestFrom(() => this.store.select(selectTrial)),
      switchMap(([, trial]) =>
        this.service
          .findAll()
          .pipe(
            map((lawOffices) =>
              LawOfficesActions.loadLawOfficesSuccess({ lawOffices })
            )
          )
      ),
      catchError((error) => {
        console.error('Error', error);
        return of(LawOfficesActions.loadLawOfficesFailure({ error }));
      })
    )
  );

  // ngrxOnInitEffects(): Action {
  //   // return LawOfficesActions.initLawOffices();
  // }
}
