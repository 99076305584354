import { Injectable } from '@angular/core';
import { Interaction, LawOffice, Trial } from '@lv/legal-battles/api-interfaces';
import { Store } from '@ngrx/store';

import * as GameActions from './game.actions';
import * as GameSelectors from './game.selectors';

@Injectable()
export class GameFacade {
  feedback$ = this.store.select(GameSelectors.selectFeedback);
  lawOffice$ = this.store.select(GameSelectors.selectLawOffice);
  loading$ = this.store.select(GameSelectors.selectLoading);
  round$ = this.store.select(GameSelectors.selectRound);
  score$ = this.store.select(GameSelectors.selectScore);
  step$ = this.store.select(GameSelectors.selectStep);
  subject$ = this.store.select(GameSelectors.selectSubject);
  paused$ = this.store.select(GameSelectors.selectPaused);
  stepCompleted$ = this.store.select(GameSelectors.selectStepCompleted);
  completed$ = this.store.select(GameSelectors.selectCompleted);

  constructor(private readonly store: Store) {}

  init() {
    this.store.dispatch(GameActions.initGame());
  }

  next(interaction: Interaction, document?: File, attachments?: File[]) {
    this.store.dispatch(GameActions.next({ interaction, document, attachments }));
  }

  setGame(lawOffice: LawOffice, trial?: Trial) {
    this.store.dispatch(GameActions.setGame({ lawOffice, trial }));
  }

  loadStep() {
    this.store.dispatch(GameActions.loadStep());
  }
}
