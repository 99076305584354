import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTrials from './+state/trials.reducer';
import { TrialsEffects } from './+state/trials.effects';
import { TrialsFacade } from './+state/trials.facade';
import { TrialsService } from './+state/trials.service';
import { SharedDataToastsModule } from '@lv/shared/data-toasts';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromTrials.TRIALS_FEATURE_KEY,
      fromTrials.trialsReducer
    ),
    SharedDataToastsModule,
    EffectsModule.forFeature([TrialsEffects]),
  ],
  providers: [TrialsService, TrialsFacade],
})
export class LegalBattlesDataTrialsModule {}
