import { Injectable } from '@angular/core';
import { loginSuccess } from '@lv/shared/data-auth';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { iif, map, mergeMap, of, tap } from 'rxjs';
import { fetch } from '@ngrx/router-store/data-persistence';

import * as SubscriptionsActions from './subscriptions.actions';
import { SubscriptionsService } from '../subscriptions.service';

@Injectable()
export class SubscriptionsEffects {
  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      map(() => SubscriptionsActions.loadSubscriptions())
    )
  );

  loadSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionsActions.loadSubscriptions),
      fetch({
        run: (action) => {
          return this.service
            .findAll()
            .pipe(
              map((subscriptions) =>
                SubscriptionsActions.loadSubscriptionsSuccess({ subscriptions })
              )
            );
        },
        onError: (action, error) => {
          console.error('Error', error);
          return SubscriptionsActions.loadSubscriptionsFailure({ error });
        },
      })
    )
  );

  loadSubscriptionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubscriptionsActions.loadSubscriptionsSuccess),
      mergeMap(({ subscriptions }) =>
        iif(
          () => subscriptions.length === 1,
          // Select the only subscription
          of(
            SubscriptionsActions.selectSubscription({
              subscription: subscriptions[0],
            })
          ),
          // Show a list of subscriptions and user pick one
          of(
            SubscriptionsActions.selectSubscription({
              subscription: subscriptions[0],
            })
          )
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: SubscriptionsService
  ) {}
}
