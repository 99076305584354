import { createAction, props } from '@ngrx/store';
import { Trial } from '@lv/legal-battles/api-interfaces';

export const initTrials = createAction('[Trials Page] Init');

export const loadTrialsSuccess = createAction(
  '[Trials/API] Load Trials Success',
  props<{ trials: Trial[] }>()
);

export const loadTrialsFailure = createAction(
  '[Trials/API] Load Trials Failure',
  props<{ error: any }>()
);

export const saveTrial = createAction(
  '[Trials/API] Save Trial',
  props<{ trial: Trial }>()
);

export const saveTrialSuccess = createAction(
  '[Trials/API] Save Trial Success',
  props<{ trial: Trial }>()
);

export const saveTrialFailure = createAction(
  '[Trials/API] Save Trial Failure',
  props<{ error: any }>()
);

export const deleteTrial = createAction(
  '[Trials/API] Delete Trial',
  props<{ trial: Trial }>()
);

export const deleteTrialSuccess = createAction(
  '[Trials/API] Delete Trial Success',
  props<{ trial: Trial }>()
);

export const deleteTrialFailure = createAction(
  '[Trials/API] Delete Trial Failure',
  props<{ error: any }>()
);
