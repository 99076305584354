import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TUTOR_FEATURE_KEY, TutorState } from './tutor.reducer';

// Lookup the 'Tutor' feature state managed by NgRx
export const selectTutorState =
  createFeatureSelector<TutorState>(TUTOR_FEATURE_KEY);

export const selectTutorTrial = createSelector(
  selectTutorState,
  (state: TutorState) => state.trial
);
