import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import * as AuthFeature from './auth.reducer';
import * as AuthSelectors from './auth.selectors';

@Injectable()
export class AuthFacade {

  accessToken$ = this.store.pipe(select(AuthSelectors.getAccessToken));

  error$ = this.store.pipe(select(AuthSelectors.getAuthError));

  user$ = this.store.pipe(select(AuthSelectors.getAuthUser));

  constructor(private readonly store: Store) {}

  login(username: string, password: string) {
    this.store.dispatch(AuthActions.login({ username, password }));
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  loadProfile() {
    this.store.dispatch(AuthActions.loadProfile());
  }

}
