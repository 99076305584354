import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastsComponent } from './toasts/toasts.component';
import { SharedDataToastsModule } from '@lv/shared/data-toasts';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  imports: [CommonModule, SharedDataToastsModule, NgbToastModule],
  declarations: [ToastsComponent, ModalComponent],
  exports: [ToastsComponent],
})
export class SharedUtilFeedbackModule {}
