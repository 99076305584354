import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from '@lv/legal-battles/api-interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>('/api/subscriptions');
  }

  save(subscription: Partial<Subscription>): Observable<Subscription> {
    if (subscription.id) {
      return this.http.put<Subscription>(`/api/subscriptions/${subscription.id}`, subscription);
    }
    return this.http.post<Subscription>('/api/subscriptions', subscription);
  }

//   delete(subscription: Subscription) {
//     return this.http.delete(`/api/subscriptions/${subscription.id}`);
//   }
}
