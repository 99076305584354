import { Component, OnInit } from '@angular/core';
import { AuthFacade } from '@lv/shared/data-auth';

@Component({
  selector: 'lv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private authFacade: AuthFacade,
  ) {

  }
  
  ngOnInit() {
    // this.authFacade.login('attore@legalbattles.it', '123');
  }
  
}
