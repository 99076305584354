import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GAME_FEATURE_KEY, GameState } from './game.reducer';

export const getGameState = createFeatureSelector<GameState>(GAME_FEATURE_KEY);

export const selectFeedback = createSelector(getGameState, state => state.feedback);
export const selectLawOffice = createSelector(getGameState, state => state.lawOffice);
export const selectTrial = createSelector(getGameState, state => state.trial);
export const selectRound = createSelector(getGameState, state => state.round);
export const selectStep = createSelector(getGameState, state => state.step);
export const selectScore = createSelector(getGameState, state => state.score);
export const selectSubject = createSelector(getGameState, state => state.subject);
export const selectLoading = createSelector(getGameState, state => state.loading);
export const selectPaused = createSelector(getGameState, state => state.paused);
export const selectStepCompleted = createSelector(getGameState, state => state.stepCompleted);
export const selectCompleted = createSelector(getGameState, state => state.completed);
