import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as ToastsActions from './toasts.actions';
import { Feedback } from '../feedbacks.models';

export const TOASTS_FEATURE_KEY = 'toasts';

export interface ToastsState {
  toasts: Feedback[];
}

export interface ToastsPartialState {
  readonly [TOASTS_FEATURE_KEY]: ToastsState;
}

export const initialToastsState: ToastsState = {
  toasts: [],
};

const reducer = createReducer(
  initialToastsState,
  on(ToastsActions.addToast, (state, { toast }) => ({ ...state, toasts: [ ...state.toasts, toast ]})),
  on(ToastsActions.removeToast, (state, { toast }) => ({ ...state, toasts: state.toasts.filter(t => t !== toast )})),
);

export function toastsReducer(state: ToastsState | undefined, action: Action) {
  return reducer(state, action);
}
