import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TRIALS_FEATURE_KEY,
  TrialsState,
  trialsAdapter,
} from './trials.reducer';

// Lookup the 'Trials' feature state managed by NgRx
export const getTrialsState =
  createFeatureSelector<TrialsState>(TRIALS_FEATURE_KEY);

const { selectAll, selectEntities } = trialsAdapter.getSelectors();

export const getTrialsLoaded = createSelector(
  getTrialsState,
  (state: TrialsState) => state.loaded
);

export const getTrialsError = createSelector(
  getTrialsState,
  (state: TrialsState) => state.error
);

export const getAllTrials = createSelector(
  getTrialsState,
  (state: TrialsState) => selectAll(state)
);

export const getTrialsEntities = createSelector(
  getTrialsState,
  (state: TrialsState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getTrialsState,
  (state: TrialsState) => state.selectedId
);

export const getSelected = createSelector(
  getTrialsEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
