import { Injectable, inject } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as LawOfficesActions from './law-offices.actions';
import * as LawOfficesFeature from './law-offices.reducer';
import * as LawOfficesSelectors from './law-offices.selectors';
import { LawOffice } from '@lv/legal-battles/api-interfaces';

@Injectable()
export class LawOfficesFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(LawOfficesSelectors.selectLawOfficesLoaded));
  allLawOffices$ = this.store.pipe(
    select(LawOfficesSelectors.selectAllLawOffices)
  );
  selectedLawOffices$ = this.store.pipe(
    select(LawOfficesSelectors.selectEntity)
  );

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(LawOfficesActions.initLawOffices());
  }
}
