import { createAction, props } from '@ngrx/store';
import { Feedback } from '../feedbacks.models';

export const initToasts = createAction('[Toasts Page] Init');

export const addToast = createAction(
  '[Toasts] Add Toast',
  props<{ toast: Feedback }>()
);

export const removeToast = createAction(
  '[Toasts] Remove Toast',
  props<{ toast: Feedback }>()
);

export const showModal = createAction(
  '[Toasts] Show Modal',
  props<{ toast: Feedback }>()
);