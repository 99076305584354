import { Injectable } from '@angular/core';
// import { ActiveEditionFacade } from '@lv/anicav/monitoraggio/data-active-edition';
import { createEffect, Actions, ofType, OnInitEffects } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { filter, map, tap } from 'rxjs';

import * as AuthActions from './auth.actions';
import * as AuthFeature from './auth.reducer';
import { AuthService } from './auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GuestFacade } from '@lv/legal-battles/data-guest';

@Injectable()
export class AuthEffects implements OnInitEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.init),
      map(() => this.service.loadFromStorage() ?? ''),
      filter((access_token) => !!access_token),
      map((access_token) => AuthActions.loginSuccess({ access_token }))
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      fetch({
        run: ({ username, password }) => {
          return this.service
            .login(username, password)
            .pipe(
              map(({ access_token }) =>
                AuthActions.loginSuccess({ access_token })
              )
            );
        },
        onError: (action, error) => {
          return AuthActions.loginFailure({ error });
        },
      })
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      // tap(() => this.activeEditionFacade.init()),
      tap(({ access_token }) => this.service.saveToStorage(access_token)),
      map(() => AuthActions.loadProfile())
    )
  );

  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadProfile),
      fetch({
        run: () => {
          return this.service
            .profile()
            .pipe(map((user) => AuthActions.loadProfileSuccess({ user })));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return AuthActions.loadProfileFailure({ error });
        },
      })
    )
  );

  loadProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadProfileSuccess),
      tap(() => this.guestFacade.performRedirect()),
    ), { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this.service.logout())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private service: AuthService,
    private guestFacade: GuestFacade,
  ) {}

  ngrxOnInitEffects() {
    return AuthActions.init();
  }
}
