import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Feedback, IdField, Interaction, StepResult } from "@lv/legal-battles/api-interfaces";
import { Observable } from "rxjs";

@Injectable()
export class GameService {

    constructor(private http: HttpClient) {}

    step(trialId: IdField): Observable<StepResult> {
        return this.http.get<StepResult>('/api/game/state/' + trialId);
    }

    next(trialId: IdField, action: Interaction, document?: File, attachments?: File[]): Observable<Feedback> {
        const formData = new FormData();
        formData.set('content', action.content);
        formData.set('data', JSON.stringify(action));

        if (document) {
            formData.append('document', document);
        }

        (attachments ?? []).forEach((attachment, index) => formData.append(`attachment_${index}`, attachment));

        return this.http.post<Feedback>('/api/game/state/' + trialId, formData);
    }

}