import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthFacade } from '../+state/auth.facade';
import { User } from '@lv/legal-battles/api-interfaces';

@Directive({
  selector: '[lvOnlyUser]'
})
export class OnlyUserDirective implements OnInit {

  user$ = this.authFacade.user$;
  subs: Subscription[] = [];
  hasView = false;
  
  constructor(
    private authFacade: AuthFacade,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnInit(): void {
    this.subs.push(this.user$.subscribe(user => this.onUser(user)));
  }

  onUser(user: User | null) {
    console.warn('OnlyUserDirective `user && !user.is_admin`')
    if (user) {
    // if (user && !user.is_admin) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

}
