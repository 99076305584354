import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as TutorActions from './tutor.actions';
import * as TutorFeature from './tutor.reducer';

import { switchMap, catchError, of } from 'rxjs';

@Injectable()
export class TutorEffects {
  private actions$ = inject(Actions);

  // init$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(TutorActions.initTutor),
  //     switchMap(() => of(TutorActions.loadTutorSuccess({ tutor: [] }))),
  //     catchError((error) => {
  //       console.error('Error', error);
  //       return of(TutorActions.loadTutorFailure({ error }));
  //     })
  //   )
  // );
  
}
