import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as ToastsActions from './toasts.actions';
import { Feedback, FeedbackType } from '../feedbacks.models';
import * as ToastsFeature from './toasts.reducer';
import * as ToastsSelectors from './toasts.selectors';

@Injectable()
export class ToastsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  allToasts$ = this.store.pipe(select(ToastsSelectors.getAllToasts));

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(ToastsActions.initToasts());
  }

  show(message: string, type: FeedbackType, heading?: string) {
    const toast: Feedback = { message, heading, type };
    this.store.dispatch(ToastsActions.addToast({ toast }))
  }

  success(message: string, heading?: string) {
    return this.show(message, 'success', heading);
  }

  failure(message: string, heading?: string) {
    return this.show(message, 'failure', heading);
  }

  warning(message: string, heading?: string) {
    return this.show(message, 'warning', heading);
  }

  info(message: string, heading?: string) {
    return this.show(message, 'info', heading);
  }

  /**
   * Note that creation methods (show, succes, ...) does not let the type Toast creep.
   * In fact, those methods may be used from outside the data-toasts library.
   * The hide() method is supposed to be called from the ToastsComponent, so it has
   * visibility on type Toast.
   * @param toast 
   */
  hide(toast: Feedback) {
    this.store.dispatch(ToastsActions.removeToast({ toast }));
  }
  
}
