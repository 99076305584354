import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as SubscriptionsActions from './subscriptions.actions';
import * as SubscriptionsFeature from './subscriptions.reducer';
import * as SubscriptionsSelectors from './subscriptions.selectors';

@Injectable()
export class SubscriptionsFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(
    select(SubscriptionsSelectors.getSubscriptionsLoaded)
  );
  allSubscriptions$ = this.store.pipe(
    select(SubscriptionsSelectors.getAllSubscriptions)
  );
  selectedSubscriptions$ = this.store.pipe(
    select(SubscriptionsSelectors.getSelected)
  );
  trial$ = this.store.pipe(
    select(SubscriptionsSelectors.getTrial)
  );

  constructor(private readonly store: Store) {}

}
