import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LawOffice } from "@lv/legal-battles/api-interfaces";
import { IdField } from "@lv/shared-api/auth";

@Injectable()
export class LawOfficesService {

    constructor(
        private http: HttpClient,
    ) {}

    find(id: IdField) {
        return this.http.get<LawOffice>(`/api/law-offices/${id}`);
    }

    findAll() {
        return this.http.get<LawOffice[]>('/api/law-offices/');
    }

}