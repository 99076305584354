import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@lv/legal-battles/api-interfaces';
import { Observable } from 'rxjs';

const ACCESS_TOKEN_STORAGE_KEY = 'access_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
  ) { }

  saveToStorage(accessToken: string) {
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }

  loadFromStorage() {
    return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  }

  removeFromStorage() {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post('/api/users/login', { password, email: email.toLocaleLowerCase().trim() });
  }

  logout() {
    this.removeFromStorage();
    return this.http.post('/api/users/logout', null);
  }

  profile(): Observable<User> {
    return this.http.get<User>('/api/users/profile');
  }

}
