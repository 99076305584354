import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as GuestActions from './guest.actions';
import { GuestEntity } from './guest.models';

export const GUEST_FEATURE_KEY = 'guest';

export interface GuestState extends EntityState<GuestEntity> {
  selectedId?: string | number; // which Guest record has been selected
  loaded: boolean; // has the Guest list been loaded
  error?: string | null; // last known error (if any)
}

export interface GuestPartialState {
  readonly [GUEST_FEATURE_KEY]: GuestState;
}

export const guestAdapter: EntityAdapter<GuestEntity> =
  createEntityAdapter<GuestEntity>();

export const initialGuestState: GuestState = guestAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const reducer = createReducer(
  initialGuestState,
  on(GuestActions.initGuest, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(GuestActions.loadGuestSuccess, (state, { guest }) =>
    guestAdapter.setAll(guest, { ...state, loaded: true })
  ),
  on(GuestActions.loadGuestFailure, (state, { error }) => ({ ...state, error }))
);

export function guestReducer(state: GuestState | undefined, action: Action) {
  return reducer(state, action);
}
