import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { Trial } from '@lv/legal-battles/api-interfaces';

import * as TrialsActions from './trials.actions';

export const TRIALS_FEATURE_KEY = 'trials';

export interface TrialsState extends EntityState<Trial> {
  selectedId?: string | number; // which Trials record has been selected
  loaded: boolean; // has the Trials list been loaded
  error?: string | null; // last known error (if any)
}

export interface TrialsPartialState {
  readonly [TRIALS_FEATURE_KEY]: TrialsState;
}

export const trialsAdapter: EntityAdapter<Trial> = createEntityAdapter<Trial>();

export const initialTrialsState: TrialsState = trialsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const reducer = createReducer(
  initialTrialsState,
  on(TrialsActions.initTrials, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(TrialsActions.loadTrialsSuccess, (state, { trials }) =>
    trialsAdapter.setAll(trials, { ...state, loaded: true })
  ),
  on(TrialsActions.loadTrialsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(TrialsActions.saveTrialSuccess, (state, { trial }) =>
    trialsAdapter.setOne(trial, { ...state })
  ),
  on(TrialsActions.saveTrialFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(TrialsActions.deleteTrialSuccess, (state, { trial }) =>
    trialsAdapter.removeOne(trial.id, { ...state })
  ),
  on(TrialsActions.deleteTrialFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function trialsReducer(state: TrialsState | undefined, action: Action) {
  return reducer(state, action);
}
