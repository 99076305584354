import { LawOffice } from '@lv/legal-battles/api-interfaces';
import { createAction, props } from '@ngrx/store';

export const initLawOffices = createAction('[LawOffices Page] Init');

export const loadLawOfficesSuccess = createAction(
  '[LawOffices/API] Load LawOffices Success',
  props<{ lawOffices: LawOffice[] }>()
);

export const loadLawOfficesFailure = createAction(
  '[LawOffices/API] Load LawOffices Failure',
  props<{ error: any }>()
);
