import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { User } from '@lv/legal-battles/api-interfaces';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  access_token: string | null;
  user: User | null;
  error?: any;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialAuthState: AuthState = {
  access_token: null,
  user: null,
}

const reducer = createReducer(
  initialAuthState,
  on(AuthActions.loginFailure, (state, { error }) => ({ ...state, error })),
  on(AuthActions.loginSuccess, (state, { access_token }) => ({ ...state, access_token, error: undefined })),
  on(AuthActions.loadProfileSuccess, (state, { user }) => ({ ...state, user })),
  on(AuthActions.logout, (state) => ({ ...state, ...initialAuthState })),
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
