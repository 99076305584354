import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as GuestActions from './guest.actions';
import * as GuestFeature from './guest.reducer';
import * as GuestSelectors from './guest.selectors';

@Injectable()
export class GuestFacade {

  loaded$ = this.store.pipe(select(GuestSelectors.getGuestLoaded));
  allGuest$ = this.store.pipe(select(GuestSelectors.getAllGuest));
  selectedGuest$ = this.store.pipe(select(GuestSelectors.getSelected));

  constructor(private readonly store: Store) {}

  init() {
    this.store.dispatch(GuestActions.initGuest());
  }

  performRedirect() {
    this.store.dispatch(GuestActions.performRedirect());
  }
}
