import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map, tap } from 'rxjs';
import { TrialsService } from './trials.service';

import * as TrialsActions from './trials.actions';
import * as TrialsFeature from './trials.reducer';
import { ToastsFacade } from '@lv/shared/data-toasts';
import { FeedbackService } from '@lv/shared/util-feedback';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class TrialsEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialsActions.initTrials),
      fetch({
        run: (action) => {
          return this.service
            .findAll()
            .pipe(map((trials) => TrialsActions.loadTrialsSuccess({ trials })));
        },
        onError: (action, error) => {
          console.error('Error', error);
          return TrialsActions.loadTrialsFailure({ error });
        },
      })
    )
  );

  save$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialsActions.saveTrial),
      fetch({
        run: (action) => {
          return this.service.save(action.trial).pipe(
            tap(() => this.router.navigate(['trials'])),
            tap((trial) =>
              this.toasts.success(`"${trial.name}" è stato salvato.`)
            ),
            map((trial) => TrialsActions.saveTrialSuccess({ trial }))
          );
        },
        onError: (action, error) => {
          this.feedback.failure(
            error.error.message,
            `Impossibile salvare "${action.trial.name}"`
          );
          return TrialsActions.saveTrialFailure({ error });
        },
      })
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TrialsActions.deleteTrial),
      fetch({
        run: (action) => {
          return this.service.delete(action.trial).pipe(
            tap(() =>
              this.toasts.success(`"${action.trial.name}" è stato eliminato.`)
            ),
            map(() => TrialsActions.deleteTrialSuccess({ trial: action.trial }))
          );
        },
        onError: (action, error) => {
          this.feedback.failure(
            error.error.message,
            `Impossibile eliminare "${action.trial.name}".`
          );
          return TrialsActions.deleteTrialFailure({ error });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private feedback: FeedbackService,
    private toasts: ToastsFacade,
    private service: TrialsService
  ) {}
}
