import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private ngbModal: NgbModal,
  ) { }

  failure(message: string, heading?: string) {
    const modal = this.ngbModal.open(ModalComponent, { windowClass: 'dark-modal' });
    modal.componentInstance.message = message;
    modal.componentInstance.heading = heading;
    modal.componentInstance.color = 'failure';
  }
}
