import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SubscriptionsFacade } from '@lv/legal-battles/data-subscriptions';
import { AuthFacade } from '@lv/shared/data-auth';
import { Observable, combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerGuard  {

  auth$ = combineLatest([
    this.authFacade.user$,
    this.subscriptionsFacade.selectedSubscriptions$,
  ]);

  constructor(
    private authFacade: AuthFacade,
    private subscriptionsFacade: SubscriptionsFacade,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.auth$.pipe(
      map(([ user, sub ]) => !!(user && sub?.role === 'PLAYER')),
      map(canActivate => canActivate || this.router.createUrlTree(['/'])),
    );
  }
  
}
