import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, Subject, tap } from 'rxjs';
import { TutorFacade } from './+state/tutor.facade';
import { Trial } from '@lv/legal-battles/api-interfaces';

@Injectable()
export class TrialInterceptor implements HttpInterceptor, OnDestroy {

  destroy$ = new Subject<void>();
  trial?: Trial;

  constructor(
    private tutorFacade: TutorFacade
  ) {
    this.tutorFacade.trial$.pipe(
      tap(trial => this.trial = trial),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.trial) {
      request = request.clone({
        headers: request.headers.append('X-LB-TRIAL-ID', `${this.trial.id}`)
      });
    }
    return next.handle(request);
  }
}
