import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { first, mergeMap, Observable } from 'rxjs';
import { AuthFacade } from '../+state/auth.facade';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authFacade: AuthFacade,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.match(/\/login$/)?.length) {
      return next.handle(request);
    }
    // https://antonyderham.me/post/angular-ngrx-auth-interceptor/
    return this.authFacade.accessToken$.pipe(
      first(),
      mergeMap(token => {
        const authReq = token ? request.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        }) : request;
        return next.handle(authReq)
      })
    );
  }
}
