import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as TutorActions from './tutor.actions';
import { Trial } from '@lv/legal-battles/api-interfaces';

export const TUTOR_FEATURE_KEY = 'tutor';

export interface TutorState {
  trial?: Trial,
}

export interface TutorPartialState {
  readonly [TUTOR_FEATURE_KEY]: TutorState;
}

export const initialTutorState: TutorState = {

};

const reducer = createReducer(
  initialTutorState,
  on(TutorActions.setTutor, (state, { trial }) => ({
    ...state,
    trial,
  })),
);

export function tutorReducer(state: TutorState | undefined, action: Action) {
  return reducer(state, action);
}
